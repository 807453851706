import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'leaflet/dist/leaflet.css';
import MapComponentHR from './MapComponentHR';
import MapComponentTX from './MapComponentTX';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/us" element={
          <div className="App" style={{ display: 'flex', height: '100vh' }}>
            <MapComponentTX />
          </div>
        } />
        <Route path="/hr" element={
          <div className="App" style={{ display: 'flex', height: '100vh' }}>
            <MapComponentHR />
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;

