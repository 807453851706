import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';

const MapComponentHR = () => {
    const zagreb = [45.6150, 15.819];
    const split = [43.081, 16.02];
    const dubrovnik = [42.407, 18.044];
    const rijeka = [45.271, 14.422];
    const osijek = [45.549, 18.655];
    const zadar = [44.194, 15.314];
    const pula = [44.866, 13.896];
    const varazdin = [46.357, 16.366];
    const sibenik = [43.739, 15.898];
    const karlovac = [45.429, 15.553];

    const customIcon = new L.Icon({
    iconUrl: '/attention.png',
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  return (
    <MapContainer center={zagreb} zoom={8} style={{ height: '100%', width: '100%' }}>
<TileLayer
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
/>

      <Marker position={zagreb} icon={customIcon}>
        <Popup>Zagreb solar park I <strong>DUST: -35% estimated capacity</strong></Popup>
      </Marker>
      <Marker position={split} icon={customIcon}>
        <Popup>Split remote <strong>DUST: -32% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={dubrovnik} icon={customIcon}>
        <Popup>Dubrovnik IV <strong>DUST: -28% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={rijeka} icon={customIcon}>
        <Popup>Sunrise I&II <strong>DUST: -15% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={osijek} icon={customIcon}>
        <Popup>Freedom solar park <strong>DUST: -8% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={varazdin} icon={customIcon}>
        <Popup>Varazdin <strong>DUST: -36% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={pula} icon={customIcon}>
        <Popup>Pula electricity <strong>DUST: -33% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={sibenik} icon={customIcon}>
        <Popup>Sibenik V <strong>DUST: -43% estimated capacity</strong></Popup>
      </Marker>
            <Marker position={karlovac} icon={customIcon}>
        <Popup>Karlovac <strong>DUST: -30% estimated capacity</strong></Popup>
      </Marker>
      
    </MapContainer>
  );
};

export default MapComponentHR;

