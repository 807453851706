import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';

const MapComponentTX = () => {
  const sabine_pass = [29.755362360369944, -93.87394097399985];
  const krk_island = [45.20038186070805, 14.533812759734303];
  const petrokemija = [45.46782341314074, 16.789858881783807];
  const positions = [sabine_pass, krk_island, petrokemija];
  
    const customIcon = new L.Icon({
    iconUrl: '/attention.png',
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  return (
    <MapContainer center={sabine_pass} zoom={4} style={{ height: '100%', width: '100%' }}>
<TileLayer
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
/>

      <Marker position={sabine_pass} icon={customIcon}>
        <Popup>Sabine Pass <strong>Excessive Flaring detected</strong></Popup>
      </Marker>
      <Marker position={krk_island} icon={customIcon}>
        <Popup>Krk Island</Popup>
      </Marker>
      <Marker position={petrokemija} icon={customIcon}>
        <Popup>Petrokemija (Kutina)</Popup>
      </Marker>
      <Polyline positions={positions} color="red" />
    </MapContainer>
  );
};

export default MapComponentTX;

